import AppStoreBadge from '@/assets/apple-store-badge.svg';
import BingoLogo from '@/assets/bingo.png';
import bumperCornLogo from '@/assets/bumper_corns_logo.png';
import GoogleBadge from '@/assets/google-play-badge.svg';
import MobRunLogo from '@/assets/mob_run_logo.jpg';
import AppButton from '@/components/AppButton';
import { GTMContext } from '@/context/GTMContext';
import {
  mobile_app_store_bingo_up_home,
  mobile_app_store_bumper_corns_home,
  mobile_app_store_mob_run_home, mobile_app_store_solitaire_up_home,
  mobile_app_store_unicorn_trivia_home,
  mobile_download_apk_bingo_up_home,
  mobile_download_apk_bumper_corns_home,
  mobile_download_apk_mob_run_home, mobile_download_apk_solitaire_up_home,
  mobile_download_apk_unicorn_trivia_home,
  mobile_google_play_bingo_up_home,
  mobile_google_play_bumper_corns_home,
  mobile_google_play_mob_run_home, mobile_google_play_solitaire_up_home,
  mobile_google_play_unicorn_trivia_home,
} from '@/utils/GTMFunctions';
import Image from 'next/image';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import UnicornTriviaLogo from './../../public/unicorn_trivia.png';
import UnicornSolitaireLogo from './../../public/unicorn_solitaire.png';

export default function Home() {
  const [browserInfo, setBrowserInfo] = useState('');
  const [androidVersion, setAndroidVersion] = useState(12);
  const { setDataLayer } = useContext(GTMContext);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      setBrowserInfo('Android');
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setBrowserInfo('IOS');
    } else {
      setBrowserInfo('Web');
    }
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      const match = userAgent.match(/Android\s([0-9\.]+)/);
      if (match) {
        setAndroidVersion(parseFloat(match[1]));
      }
    }
  }, []);

  const APKLinkBingo =
    androidVersion <= 7
      ? `https://bingo.unicornparty.com/apk/unicornbingo-latest-armeabi-v7.apk`
      : `https://bingo.unicornparty.com/apk/unicornbingo-latest-arm64-v8.apk`;

  const APKLinkSolitaire =
    androidVersion <= 7
      ? `https://solitaire.unicornparty.com/apk/unicornsolitaire-latest-armeabi-v7.apk`
      : `https://solitaire.unicornparty.com/apk/unicornsolitaire-latest-arm64-v8.apk`;

  return (
      <div className="container">
        <div className="hero-container">
          <img className="hero-image left-image" src="/buddy_gummies.png" alt="characters" />
          <div className="hero-content">
            <div className="hero-content_subtitle">
              As of 02/08, sadly, Unicorn Party will be shutting down for good. The Unicorn Party project was launched with the goal of bridging the gap between real-money gamers and web3 gaming. We envisioned Crypto Unicorns as an inclusive IP, appealing to a broad spectrum of gamers and exploring the potential intersections between traditional and blockchain-based gaming.
              As the web3 landscape continues to evolve, it’s crucial to recognize that market conditions today differ significantly from those when the DAO initially invested in this venture. Despite initial enthusiasm and the innovative thesis, the current environment has necessitated a reassessment of the strategy.
              Even though this is sad news, there is plenty of fun to be had at <a href="https://www.cryptounicorns.fun/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>cryptounicorns.fun</a>.
            </div>
          </div>
          <img className="hero-image right-image" src="/gummies_right.png" alt="characters" />
        </div>
      </div>
  );
}
